import $ from "jquery";
import { getCookie } from "./utils";

const EventsList = () => {

    const MODELS = $("#nx-events-list-models");

    if ($("#nx-events-list-items").length && MODELS.length) {
        nxGetEventsList(true);

        let next = $("#nx-events-list-next");

        if (next.length) {
            setInterval(function () {
                let wS = $(this).scrollTop();
                let wH = $(window).height();
                let hT = next.offset().top;
                let hH = next.outerHeight();
                if (!$("#nx-events-list-loading").is(":visible") && next.data("next") == "true" && wS > (hT + hH - wH) && (hT > wS) && (wS+wH > hT+hH)) {
                    nxGetEventsList();
                }
            }, 1000);
        }
    }

    $(".nx-tabs-event-category").on("click", function (event) {
        event.preventDefault();
        let next = $("#nx-events-list-next");
        if (next.length) {
            next.data("url", null);
        }
        let tabs = $(this).closest(".nx-tabs-event-categories");
        let tab = $(this).closest("li");
        let value = $(this).data("value");
        tabs.data("category", value);
        tabs.children("li").removeClass("active");
        tab.addClass("active");
        nxGetEventsList(true);
    });

    $(".nx-tabs-event-location").on("click", function (event) {
        event.preventDefault();
        let next = $("#nx-events-list-next");
        if (next.length) {
            next.data("url", null);
        }
        let tabs = $(this).closest(".nx-tabs-event-locations");
        let tab = $(this).closest("li");
        let value = $(this).data("value");
        tabs.data("location", value);
        tabs.children("li").removeClass("active");
        tab.addClass("active");
        nxGetEventsList(true);
    });

    function nxGetEventsList (fresh=false) {
        const LANG = getCookie("django_language", "fr");
        let category = $(".nx-tabs-event-categories");
        let location = $(".nx-tabs-event-locations");
        let events_list = $("#nx-events-list-items");
        let loader = $("#nx-events-list-loading");
        let next = $("#nx-events-list-next");
        let param_category = category.data("category") ? category.data("category") : "all";
        let param_location = location.data("location") ? location.data("location") : "all";
        // ===---
        let url = `/${LANG}/shop-api/events/list/?category=${param_category}&location=${param_location}`;
        if (next.length && next.data("url")) {
            url = next.data("url");
        }
        // ===---
        if (fresh) {
            events_list.html("");
        }
        // ===---
        loader.show();
        category.css("pointer-events", "none");
        location.css("pointer-events", "none");
        if (next.length) {
            next.data("url", null);
        }
        $.get(url).done((r) => {
            setTimeout(function () {
                if (r.results.length) {
                    nxSetEventsList(r.results);
                } else {
                    let cloned_line = $("#nx-events-list-models").find(".nx-event-item-none").clone();
                    cloned_line.appendTo(events_list);
                    next.data("next", "false");
                }
                if (next.length) {
                    if (r.next) {
                        next.data("next", "true");
                        next.data("url", r.next);
                    } else {
                        next.data("next", "false");
                    }
                }
                loader.hide();
                category.css("pointer-events", "auto");
                location.css("pointer-events", "auto");
            }, 500);
        }).fail((error) => {
            console.log("API error:", error);
            let cloned_line = $("#nx-events-list-models").find(".nx-event-item-none").clone();
            cloned_line.appendTo(events_list);
            next.data("next", "false");
            loader.hide();
            category.css("pointer-events", "auto");
            location.css("pointer-events", "auto");
        });
    }

    function nxSetEventsList (events) {
        const LANG = getCookie("django_language", "fr");
        events.forEach(function (el, index) {
            let events_list = $("#nx-events-list-items");
            let models = $("#nx-events-list-models");
            let doublebottom;
            let coldoublebottom;
            let cloned_line;
            // ===---
            let title = el["name"];
            let description = el ["description_short"];
            let locations = el ["location"];
            let categories = el ["categories"];
            let price = el["price"];
            let img_url = el["thumbnail"] ? el["thumbnail"] : null;
            let img_title = el["image_main_title"];
            let date = el["upcoming_date"];
            let url_slug = "#";
            if (LANG == "en") {
                url_slug = `/en/events/${el["id"]}-${el["slug_en"]}/`;
            } else {
                url_slug = `/fr/evenements/${el["id"]}-${el["slug_fr"]}/`;
            }
            // ===---
            if (index === 0) {
                cloned_line = models.find(".nx-event-item-left").clone();
            } else if (index === 1 && events.length > 2) {
                doublebottom = models.find(".nx-event-item-doublebottom").clone().appendTo(events_list);
                coldoublebottom = models.find(".nx-event-item-doublebottom-left").clone().appendTo(doublebottom);
                cloned_line = models.find(".nx-event-item-fullbottom").clone();
            } else if (index === 1) {
                cloned_line = models.find(".nx-event-item-fullbottom").clone();
            } else if (index === 2) {
                doublebottom = events_list.find(".nx-event-item-doublebottom").last();
                coldoublebottom = models.find(".nx-event-item-doublebottom-right").clone().appendTo(doublebottom);
                cloned_line = models.find(".nx-event-item-fullbottom").clone();
            } else if (index === 3) {
                cloned_line = models.find(".nx-event-item-left").clone();
            } else if (index === 4) {
                cloned_line = models.find(".nx-event-item-right").clone();
            } else if (index === 5) {
                cloned_line = models.find(".nx-event-item-fullbottom").clone();
            }
            // ===---
            if (cloned_line) {
                cloned_line.css('cursor', 'pointer');
                cloned_line.on('click', function(e) {
                    e.preventDefault();
                    window.location.href = url_slug;
                });
                // ===---
                if (img_url) {
                    cloned_line.find(".thumb").css("background-image", `url('${img_url}')`);
                    cloned_line.find(".thumb").attr("alt", img_title);
                } else if (categories[0]) {
                    cloned_line.find(".thumb").css("background-color", categories[0]["colour"]);
                    cloned_line.find(".thumb").attr("alt", "");
                } else {
                    cloned_line.find(".thumb").css("background-color", "#191b23");
                    cloned_line.find(".thumb").attr("alt", "");
                }
                if (categories[0]) {
                    cloned_line.find(".title-wrapper .decor").css("background-color", categories[0]["colour"]);
                }
                cloned_line.find(".title-wrapper h3").text(title);
                cloned_line.find(".title-wrapper .nx-event-item-link").attr("href", url_slug);
                cloned_line.find(".nx-event-item-description").html(description);
                let firstLoc = cloned_line.find(".tag-location > span").first();
                let locContainer = cloned_line.find(".locations-container");
                let containerToClone = cloned_line.find(".location-tag");
                locations.forEach(function (loc, index) {
                    if (index === 0) {
                        firstLoc.html(loc["name"]);
                    } else {
                        let instance = containerToClone.clone();
                        instance.find("span").html(loc["name"]);
                        instance.appendTo(locContainer);
                    }
                });

                cloned_line.find(".price-tag > span").html(price);
                cloned_line.find(".nx-upcoming-events-date > span").html(date);
                // ===---
                if ((index === 1 && events.length > 2) || index === 2) {
                    cloned_line.appendTo(coldoublebottom);
                } else {
                    cloned_line.appendTo(events_list);
                }
            }
        });
    }

};

export default EventsList;
